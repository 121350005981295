/* Improved Background */
body {
  background:  #2a5298;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Container */
.container {
  text-align: center;
  padding: 20px;
  position: relative;
}

/* Border Around Both Teams */
.teams-wrapper {
  display: flex;
  justify-content: space-around;
  width: 95%;
  margin: 30px auto;
  padding: 15px;
}

/* Make Each Team Display in 3 Rows */
.team-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 48%;
  border: 2px solid transparent;
  border-radius: 10px;
}

.team-container.has-champions {
  border-color: rgba(255, 255, 255, 0.5);
}

/* Keep the header styling */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;
}

/* Make the title smaller */
.title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

/* Champion Categories */
.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.category-button {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  border: 2px solid #007bff;
  background-color: white;
  color: #007bff;
  transition: all 0.3s ease;
}

.category-button.selected {
  background-color: #007bff;
  color: white;
}

/* Controls - Same Row */
.controls {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Randomize Button */
.randomize-button {
  padding: 8px 15px;
  font-size: 0.9rem;
  background-color: #ff9800;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.randomize-button:hover {
  background-color: #e68900;
}

/* Teams Display */
.teams-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 15px;
}

/* Team Titles */
.team-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

/* Champion Grid - More Champions Per Row */
.champion-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 5px; /* Smaller gap */
  max-width: 600px;
}

/* Adjust champion layout */
.champion-card {
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  padding: 5px;
  border: none;
}

/* Make champions smaller */
.champion-icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

/* Champion name is beside the icon */
.champion-name {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

/* Match Language Buttons to Dropdown Style */
.language-button {
  padding: 5px !important;
  font-size: 0.8rem !important;
  border-radius: 5px;
  border: 1px solid white !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  transition: all 0.3s ease;
  width: 120px; /* Match dropdown width */
  height: 32px; /* Match dropdown height */
  text-align: center;
}

.language-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

/* Fix Toggle Button Size */
.toggle-button {
  padding: 8px 15px;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid white;
  color: white !important;
  background-color: transparent;
  width: 180px; /* Ensure same width */
  height: 36px; /* Ensure same height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button.enabled {
  background-color: #28a745;
  color: white !important;
}

.toggle-button.disabled {
  background-color: #dc3545;
  color: white !important;
}

/* Prettier Text Field */
.team-size-input {
  width: 80px;
  padding: 7px 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  outline: none;
  transition: all 0.3s ease;
}

.team-size-input:hover, .team-size-input:focus {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: white;
}

.champions-list-link {
  font-size: 0.9rem;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 120px; /* Match dropdown width */
  height: 18px; /* Match dropdown height */
}

.champions-list-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.champions-list-container {
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}